






























































































import { Component, Vue } from 'vue-property-decorator';
import CBudgetSumCalcFilter from '@/modules/budget-request/components/budget-sum-calc/budget-sum-calc-filter.vue';
import CBudgetSumCalcTab from '@/modules/budget-request/components/budget-sum-calc/budget-sum-calc-table.vue';
import Multiselect from 'vue-multiselect';
import { Ax } from '@/utils';
import CBudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import store from '@/services/store';
import { vue } from '@/services/i18n';
import BpI18nHandlerMixin from './mixins/bp-i18n-handler-mixin';

@Component({
    components: {
        'c-budg-filter': CBudgetSumCalcFilter,
        'multiselect': Multiselect,
        'c-bud-sum-calc-tab': CBudgetSumCalcTab,
        'c-budg-form-lst': CBudgetFormsList
    }
})

export default class CBudgetSumCalc extends Vue {
    private curFormSelect = '/budget-sum-calc';
    private headerData: any | null = null;
    private header: any | null = null;
    private showAdd = false;
    private error: any;
    private dataTypeFilter: any = null;

    private enableTab = false;
    private editableTab = false;

    private filter: any = [];

    private save() {
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.budSumCalcTab.save();
    }

    private getFormText(field: string, id: any = -1): any {
        const link = 'modules.budget_request.form_gu.' + field
        if (id >= 0) return this.$tc(link, id);
        return this.$t(link);
    }

    private infoCostForm(info_id: any) {
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                let file = '';
                if (info_id == 10) {
                    file = 'РП_Бюджетные_заявки_сводные_расчеты';
                }
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }

    private chgData(data: any) {
        this.filter = data;
        const curHeader = data;
        const budgetVers = curHeader.curVariant ? curHeader.curVariant.variant_uuid : null;
        if (curHeader !== null) {
            this.header = {
                year: curHeader.curYear,
                // eslint-disable-next-line @typescript-eslint/camelcase
                data_type: curHeader.dataType ? curHeader.dataType.code: null,
                gu: curHeader.gu ? curHeader.gu.code: null,
                budgetVersion: budgetVers,
                budgetVersionName: curHeader.curVariant ? curHeader.curVariant.name : null,
                // eslint-disable-next-line @typescript-eslint/camelcase
                id_region: curHeader.region.code,
                regionName: curHeader.region ? curHeader.region.name: null,
                abp: curHeader.abp.abp,
                prg: curHeader.prg ? curHeader.prg.prg: null,
                ppr: (curHeader.ppr === null ? null : curHeader.ppr.ppr),
                locale: this.$i18n.locale,
                gr: curHeader.gr
            };
            this.dataTypeFilter = curHeader.dataType.name;
        }
    }

    private openFilterByRef(refName: string) {
        const foo: any = this.$refs.budgetHeader;
        foo.openFilterByRef(refName);
    }

    private downloadRep() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        this.header.locale = this.$i18n.locale;
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc57/57/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.getFormText('app_57.dwnld_title')}.xls`);// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }

    private downloadRepSV() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        this.header.locale = this.$i18n.locale;
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc57/58/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.getFormText('app_58.dwnld_title')}.xls`);// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }
    private downloadRepBP() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        this.header.locale = this.$i18n.locale;
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc59/59/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.getFormText('app_59.dwnld_title')}.xls`);// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }
    private downloadRepTBP() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        this.header.locale = this.$i18n.locale;
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc60/60/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.getFormText('app_60.dwnld_title')}.xls`);// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }
    private downloadRepBPR() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        this.header.locale = this.$i18n.locale;
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc61/61/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.getFormText('app_61.dwnld_title')}.xls`);// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                that.error = error;
            }
        );
    }

    private isDownloadButtonEnabled = false;

    private downloadBatchRep() {
        this.isDownloadButtonEnabled = true;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        this.header.locale = this.$i18n.locale;
        const that = this;
        Ax<Blob>(
            {
                url: '/api-py/budg_sum_calc_batch_reports/' + JSON.stringify(that.header),
                method: 'POST',
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.getFormText('common.batch_dwnld')}.xls`);// or any other extension
                document.body.appendChild(link);
                link.click();
                this.isDownloadButtonEnabled = false;
            },
            (error) => {
                that.error = error;
                this.isDownloadButtonEnabled = false;
            }
        );
    }

    private mounted() {
        this.getModulesAccess();
        this.$watch('store.state.user.userModules', () => { this.getModulesAccess(); });
    }

    // ------------------
    private getModulesAccess() {
        const access = this.getAccess('004.002.003');
        this.enableTab = access.enable;
        this.editableTab = access.editable;
    }

    private getAccess(code: string) {
        const modules: any[] = store.state.user.userModules;
        let editable = false;
        let enable = false;
        for (const row of modules) {
            if (row.modules === code) {
                if (row.access_level !== 1) {
                    editable = true;
                }
                if ([1, 2, 3].includes(row.access_level)) {
                    enable = true;
                }
            }
        }
        return { editable, enable };
    }

    // ----------------
}































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { setNameLang, makeToast } from '@/modules/budget-request/components/js/some-functions';
import { variantNameLangSupport } from '@/modules/budget/budgetCorrectHelper';
import store from '@/services/store';

@Component({
    name: 'c-budg-filter'
})
export default class CBudgetSumCalcFilter extends Vue {

    private budgetLevel: number[] = [];
     // -----------
     private variantEndDate: any = null; // дата окончания вариантов, для ограничения справочников фильтров по датам
    // --------

    private funcGr: number | null = null;

    // ------ Период
    private curYear = (new Date()).getFullYear();
    private periodLst: any[] = [];
    private curPeriod: any = null;

    private getPeriodLst() {
        const year = (new Date()).getFullYear();
        this.periodLst = [];
        for (let i = year - 2020 + 1; i > 0; i--) {
            this.periodLst.push({ name: `${2020 + i} - ${2020 + i + 2}`, year: 2020 + i });
        }
        this.curPeriod = { name: `${year + 1} - ${year + 3}`, year: year + 1 };
        this.curYear = this.curPeriod.year;
    }

    // ----------- Регион
    private obl: string | null = null;
    private regionBase: any[] = [];
    private curRegion: any | null = null;

    private get getRegion() {
        const result: any[] = [];
        for (const el of this.regionBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        if (this.curRegion !== null && !this.curRegion.name) { this.curRegion = setNameLang(this.$i18n.locale, this.curRegion, 'code'); }
        return result;
    }

    // ------------вид данных-----------
    private dataTypeDictBase: any[] = [];
    private curDataType: any | null = null;

    private get dataTypeDict(): any[] {
        const result: any[] = [];
        for (const el of this.dataTypeDictBase) {
            result.push(setNameLang(this.$i18n.locale, el, 'code'));
        }
        if (this.curDataType !== null && !this.curDataType.name) { this.curDataType = setNameLang(this.$i18n.locale, this.curDataType, 'code'); }
        return result;
    }

    private chgDataType() {
        this.loadVariants();
    }

    private get versYear() {
        if (!this.curDataType) { return null; }
        return (this.curDataType.code == '2' ? this.curVariant.year: null);
    }

    // ------------- версия бюджета
    private curVariant: null | any = null;
    private variantBase: any[] = [];

    private get variantLst(): any[] {
        const res: any[] = [];
        for (const el of this.variantBase) {
            const tmpObj = setNameLang(this.$i18n.locale, el);
            res.push(tmpObj);
        }
        if (this.curVariant) { this.curVariant = setNameLang(this.$i18n.locale, this.curVariant); }
        return res;
    }

    private async chgCurVariant() {
        await this.getVariantEndDate();
        this.getBudgetLevel()
        // this.chgEvent('watch curVariant');
    }

    // ------------------ администратор программ ------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(setNameLang(this.$i18n.locale, el, 'abp'));
        }

        if (this.curAbp !== null && !this.curAbp.name) { this.curAbp = setNameLang(this.$i18n.locale, this.curAbp, 'abp'); }
        return res;
    }

    // --------- гос учреждение ------------
    private guBase: any[] = [];
    private curGu: any | null = null;

    private get gu(): any[] {
        const result: any[] = [];
        for (const el of this.guBase) {
            if (el.code) {
                result.push(setNameLang(this.$i18n.locale, el, 'code'));
            } else {
                result.push(el);
            }
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }

    // ------------------------ программа --------------------------
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(setNameLang(this.$i18n.locale, el, 'prg'));
        }
        if (this.curProg !== null) { this.curProg = setNameLang(this.$i18n.locale, this.curProg, 'prg'); }
        return res;
    }

    private async chgProg() {
        if (this.curProg) {
            this.funcGr = this.curProg.gr;
        } else {
            this.funcGr = null;
        }
        await this.loadSubProg();
    }

    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(setNameLang(this.$i18n.locale, el, 'ppr'));
        }
        if (this.curSubProg !== null) { this.curSubProg = setNameLang(this.$i18n.locale, this.curSubProg, 'ppr'); }
        return res;
    }
    // --------------------

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    public openFilterByRef(refName: any) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    /**
     * загрузка области
     */
    private async loadObl() {
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);
            if (result.status === 200) {
                const json = await result.json();
                this.obl = json.obl;
            } else {
                makeToast(this, 'danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
        }

        if (!this.usrId) { return; }

        try {
            await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
                .then(response => response.json())
                .then(json => {
                    if (json.length && json[0].code) {
                        json.sort((a: any, b: any) => (a.code - b.code > 0) ? 1 : -1);
                        this.regionBase = json;
                    }

                });
        } catch (error) {
            this.regionBase = [];
            makeToast(this, 'danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
        }
        if (this.regionBase.length) { this.curRegion = setNameLang(this.$i18n.locale, this.regionBase[0], 'code'); }
    }

    /**
     * загрузка типа данных
     */
    private async loadDataTypeDict() {
        let result: any[] = [];
        try {
            result = await fetch('/api-py/dict_budget_data_types/')
                .then(response => response.json());
        } catch (error) {
            result = [];
            makeToast(this, 'danger', 'Ошибка загрузки вида данных', (error as Error).toString());
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        if (result.length > 0) {
            this.curDataType = setNameLang(this.$i18n.locale, result[0], 'code');
        }
        this.dataTypeDictBase = result;
    }

    /**
     * загрузка вариантов
     */
    private async loadVariants() {
        this.variantBase = [];
        if (!this.curYear || !this.curDataType || !this.curRegion || !this.usrId) { return; }
        // await this.loadActiveVariant();
        const params = { year: this.curYear, dataType: this.curDataType.code, region: this.curRegion.code };
        const url = `/api-py/get-variants-for-calcsum/${encodeURI(JSON.stringify(params))}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                for (const el of response) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    el.name_ru = variantNameLangSupport(el, 'ru');
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    el.name_kk = variantNameLangSupport(el, 'kk');
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    el.name_en = variantNameLangSupport(el, 'en');
                }
                this.variantBase = response;
                if (this.variantBase.length) {
                    const tmpObj = this.getFirstActiveVariant();
                    if (!this.curVariant || this.curVariant.variant_uuid!== tmpObj.variant_uuid) {
                        const flNull = (this.curVariant === null);
                        this.curVariant = tmpObj;

                        this.chgCurVariant();
                    }
                }
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки вариантов', `${response.status} - ${response.statusText}`);
                this.curVariant = null;
                this.chgCurVariant();
            }
        } catch (error) {
            this.curVariant = null;
            this.chgCurVariant();
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки вариантов', (error as Error).toString());
        }
    }

    /**
     * загрузка АБП
     */
    private async loadAbp() {
        if (!this.curVariant || !this.budgetLevel || this.budgetLevel.length < 1) {
            this.curAbp = null;
            return;
        }
        const url = `/api-py/get-dict-ved-abp-by-budgetlevel-user-dateend/${encodeURI(JSON.stringify(this.budgetLevel))}/${this.usrId}/${encodeURI(this.curVariant.date_start)}/${encodeURI(this.variantEndDate)}/${encodeURI(this.versYear)}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки адм. программ', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки адм. программ', (error as Error).toString());
        }

        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');
        if (this.abpBase.length > 0) {
                this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
    }

    /**
     * загрузка ГУ
     */
     private async loadGu() {
        if (!this.curVariant || !this.curAbp || !this.curRegion || !this.usrId) {
            this.guBase = [];
            return;
        }
        let result: any[] = [];
        this.curGu = null;
        if (this.curAbp !== null) {
            try {
               result = await fetch(`/api-py/get-gu-by-abp-user-id-region-date-e-b/${this.curAbp.abp}/${this.usrId}/${this.curRegion.code}/${encodeURI(this.curVariant.date_start)}/${encodeURI(this.variantEndDate)}/${encodeURI(this.versYear)}`)
                    .then(response => response.json());
            } catch (error) {
                result = [];
                makeToast(this, 'danger', 'Ошибка загрузки гос. учреждений', (error as Error).toString());
            }
            if (result.length > 0) {
                this.curGu = setNameLang(this.$i18n.locale,  result[0], 'code');
            }
            this.guBase = result;
        }
    }

    /**
     * загрузка Программ
     */
    private async loadProg() {
        if (!this.curAbp) {
            this.curProg = null;
            return;
        }
        let response: any = [];
        this.progBase = [];
        const params = { abp: this.curAbp.abp, date_b: this.curVariant.date_start, date_e: this.variantEndDate, vers_year: this.versYear };
        try {
            response = await fetch(`/api-py/get-dict-func-prog-subprog/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                response = await response.json();
            } else {
                response = [];
                makeToast(this, 'danger', 'Ошибка загрузки программ', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки программ', (error as Error).toString());
        }

        response.sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
        this.progBase = this.setIdArr(response, 'prg');

        if (this.progBase.length > 0) {
            this.curProg = setNameLang(this.$i18n.locale,  this.progBase[0], 'prg');
        } else {
            this.curProg = null;
        }
        await this.chgProg();
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];
        this.curSubProg = null;
        if (this.curProg !== null) {
            const params = { abp: this.curAbp.abp, date_b: this.curVariant.date_start, date_e: this.variantEndDate, prg: this.curProg.prg, vers_year: this.versYear };
            try {
                response = await fetch(`/api-py/get-dict-func-prog-subprog/${encodeURI(JSON.stringify(params))}`);
                if (response.status === 200) {
                    response = await response.json();
                } else {
                    response = [];
                    makeToast(this, 'danger', 'Ошибка загрузки подпрограмм', `${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                makeToast(this, 'danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
                response = [];
            }
        }

        response.sort((a: any, b: any) => (a.ppr - b.ppr > 0) ? 1 : -1);
        this.subProgBase = this.setIdArr(response, 'ppr');

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
        } else {
            this.curSubProg = null;
        }
        this.chgEvent('loadSubProg');
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({ idArr: [el.id] }, el));
            }
        }
        return result;
    }

    private getFirstActiveVariant() { // получить первый активный вариант
        for (let i = 0; i < this.variantBase.length; i++) {
            const el = this.variantBase[i];
            if (el.attribute) {
                return setNameLang(this.$i18n.locale, el);
            }
        }
        if (this.variantBase.length) { return setNameLang(this.$i18n.locale, this.variantBase[0]); }
        return null;
    }

    private getBudgetLevel() {
        this.budgetLevel = [];
        if (this.curRegion) {
            if (this.curRegion.code.slice(this.curRegion.code.length - 4) === '0101') {
                this.budgetLevel.push(2);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) === '01')  {
                this.budgetLevel.push(3);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) !== '00') {
                this.budgetLevel.push(4);
            }
        }
        this.abpBase = [];
        this.loadAbp();
    }

    /**
     * получение variantStartDate для ограничение действия справочников в фильтрах
     */
     private async getVariantEndDate() {
        if (!this.curVariant) {
            this.variantEndDate = null;
            return;
        }
        if (this.curVariant.date_ueb) {
            this.variantEndDate = this.curVariant.date_ueb;
            return;
        }
        if (this.curVariant.attribute === true && this.curVariant.status === false && !this.curVariant.is_deleted) {
            this.variantEndDate = null;
            return;
        }

        let response: any = [];
        try {
            response = await fetch(`/api-py/get-next-variant-date-start/${encodeURI(this.curVariant.date_start)}`);
            if (response.status === 200) {
                response = await response.json();
                this.variantEndDate = response.date;
            } else {
                this.variantEndDate = null;
                makeToast(this, 'danger', 'Ошибка get-next-variant-date-start', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            this.variantEndDate = null;
            makeToast(this, 'danger', 'Ошибка get-next-variant-date-start', (error as Error).toString());
        }
    }

    private mounted() {
        this.getPeriodLst();
        this.loadDataTypeDict();
        this.loadObl();
        this.loadVariants();

        this.$watch('curPeriod', async () => {
            if (this.curPeriod) {
                this.curYear = this.curPeriod.year;
                await this.loadActiveVariant();
                this.loadVariants();
            }
        });
        this.$watch('usrId', () => {
            if (!this.usrId) { return; }
            this.loadObl();
            this.loadVariants();
        });
        this.$watch('curRegion', async () => {
            await this.loadActiveVariant();
            this.loadVariants();
        });
        this.$watch('curAbp', async () => {
            this.loadGu();
            this.loadProg();
            // await this.chgEvent('watch curAbp') };
        });
        this.$watch('curGu', () => {
            // this.chgEvent('watch curGu');
        });
    }

        // ----- закгрузка активного варианта
        private async loadActiveVariant() {
        if (!this.curYear || !this.curRegion) { return; }
        const params = { year: this.curYear, region: this.curRegion.code };
        const url = `/api-py/get--active-variants-for-calcsum/${encodeURI(JSON.stringify(params))}`;
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                if (response.length) {
                    for (let i = 0; i < this.dataTypeDict.length; i++) {
                        if (response[0].data_type.toString() === this.dataTypeDict[i].code) {
                            this.curDataType = this.dataTypeDict[i];
                            break;
                        }
                    }
                }
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки активных вариантов', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            makeToast(this, 'danger', 'Ошибка загрузки активных вариантов', (error as Error).toString());
        }
    }

    private chgEvent(source: string) {
        const params = { curYear: this.curYear, abp: this.curAbp, dataType: this.curDataType, region: this.curRegion, curVariant: this.curVariant, gu: this.curGu, variantEndDate: this.variantEndDate, prg: this.curProg, ppr: this.curSubProg, gr: this.funcGr };
        this.$emit('chgData', params);
    }

}
